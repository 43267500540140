import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("../views/MyIndex.vue"),
      },
      {
        path: "/situation",
        name: "situation",
        component: () => import("../views/SituationView.vue"),
      },
      {
        path: "/case",
        name: "case",
        component: () => import("../views/CaseView.vue"),
      },
      {
        path: "/department/:num",
        name: "department",
        component: () => import("../views/DepartmentView.vue"),
      },
      {
        path: "/experts",
        name: "experts",
        component: () => import("../views/ExpertsView.vue"),
      },
      {
        path: "/guide/:num",
        name: "guide",
        component: () => import("../views/GuideView.vue"),
      },
      {
        path: "/mission",
        name: "mission",
        component: () => import("../views/MissionView.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/NewsView.vue"),
      },
      {
        path: "/nursing",
        name: "nursing",
        component: () => import("../views/NursingView.vue"),
      },
      {
        path: "/sanitation",
        name: "sanitation",
        component: () => import("../views/SanitationView.vue"),
      },
      {
        path: "/addNews",
        name: "addNews",
        component: () => import("../views/AddNews.vue"),
      },
      {
        path: "/addArticle",
        name: "addArticle",
        component: () => import("../views/AddArticle.vue"),
      },
      {
        path: "/publish",
        name: "publish",
        component: () => import("../views/PublishView.vue"),
      },
      {
        path: "/appointment",
        name: "appointment",
        component: () => import("../views/AppointmentView.vue"),
      },
      {
        path: "/appointmentList",
        name: "appointmentlist",
        component: () => import("../views/AppointmentList.vue"),
      },
      {
        path: "/articleDetail",
        name: "articleDetail",
        component: () => import("../views/ArticleDetail.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
