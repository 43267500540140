import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "@/theme/index.css"
import "@/assets/css/style.css";
import "@/assets/css/index.css";

// import "@/static/ueditor-1.5.0/ueditor.config"
// import "@/static/ueditor-1.5.0/ueditor.all"
// import "@/static/ueditor-1.5.0/lang/zh-cn/zh-cn"

Vue.config.productionTip = false;

Vue.use(ElementUI);

Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
