<template>
  <div style="background-color: #f4f7f7; min-width: 1300px">
    <!-- 顶部 -->
    <div class="top">
      <div class="con">
        <div class="lf">
          <span class="yx">邮箱：srgfgdyy@126.com</span> <span>|</span>
          <span class="dh">咨询热线：079-3795-2180</span>
        </div>
        <div class="rt">
          <a href="#" class="wb"></a>
          <a href="#" class="qq"></a>
          <a href="#" class="wx"></a>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- 头部标题 -->
    <div style="background-color: #fff">
      <div class="header con">
        <div class="logo">
          <img src="@/assets/img/logo.jpg" />
        </div>
        <div class="nav">
          <div v-for="(nav, i) in navList" :key="i" @click="changeNav(nav)">
            <div>{{ nav.name }}</div>
            <div class="eng-name">{{ nav.engName }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->
    <router-view></router-view>
    <!-- 脚部 -->
    <div class="footer" style="margin-top: 20px">
      <div class="foot">
        <div class="foot11">
          <div class="weixin lf">
            <img src="@/assets/img/qr_code.jpg" width="100" height="100" />
          </div>
          <div class="lxfs lf" style="margin-top: 12px">
            电话：079-3795-2180 <br />
            邮箱: srgfgdyy@126.com<br />
            传真: 12345678<br />
            地址：广丰区永丰街道开源路766号（南山加油站东侧）<br />
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="bq">
        <div class="nr">
          <span
            >2022-2028&emsp;&emsp;15856465867&emsp;&emsp;上海亿筱杨科技有限公司
          </span>
          <a href="https://beian.miit.gov.cn/" target="_blank">
            赣ICP备2023008594号-1
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",

  data() {
    return {
      // imgUrl: process.env.BASE_URL,
      navList: [
        { name: "网站首页", engName: "HOME", url: "/" },
        { name: "医院概况", engName: "Situation", url: "/situation" },
        { name: "预约挂号", engName: "Appointment", url: "/appointment" },
        { name: "新闻公告", engName: "News", url: "/news" },
        { name: "科室导航", engName: "Department", url: "/department/list" },
        { name: "专家团队", engName: "Experts", url: "/experts" },
        // { name: "卫生城市", engName: "Sanitation", url: "/sanitation" },
        { name: "典型案例", engName: "CASE", url: "/case" },
        { name: "护理天地", engName: "Nursing", url: "/nursing" },
        { name: "健康宣教", engName: "Misson", url: "/mission" },
        { name: "就医指南", engName: "Guide", url: "/guide/0" },
      ],
    };
  },
  methods: {
    changeNav(nav) {
      this.$router.push({ path: `${nav.url}` });
    },
  },
};
</script>


<style>
.swiper-container {
  width: 100%;
  height: 500px;
}

.top {
  width: 100%;
  background-color: #fafafa;
  font-size: 12px;
  line-height: 36px;
  color: #999;
}

.top span {
  display: block;
  float: left;
}

.top span.yx {
  background: url(@/assets/img/yx.png) no-repeat left center;
  padding-left: 25px;
  margin-right: 15px;
}

.top span.dh {
  background: url(@/assets/img/dh.png) no-repeat left center;
  padding-left: 22px;
  margin-left: 15px;
}

.top span.dl {
  background: url(@/assets/img/ch.gif) no-repeat left center;
  padding-left: 25px;
  margin-right: 15px;
}

.top span.zc {
  background: url(@/assets/img/en.gif) no-repeat left center;
  padding-left: 27px;
  margin-left: 13px;
  margin-right: 10px;
}

.top span.dl1 {
  background: url(@/assets/img/dl.png) no-repeat left center;
  padding-left: 25px;
  margin-right: 15px;
  margin-left: 10px;
}

.top span.zc1 {
  background: url(@/assets/img/zc.png) no-repeat left center;
  padding-left: 27px;
  margin-left: 13px;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.98);
  border-bottom: 1px solid #f7f7f7;
  z-index: 9999;
}

.header .logo {
  width: 12%;
  padding: 10px 0;
}

.header .logo img {
  width: 70%;
  height: auto;
  display: block;
}

.header .nav {
  flex: 1 1 100px;
  padding: 10px 30px 20px 0;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header .nav > :hover {
  background-color: #006498;
  color: #fff;
  cursor: pointer;
}

.header .nav > :hover a,
.header .nav > :hover span {
  color: white;
}

.header .nav > div {
  padding: 20px 5px;
}

.header .nav .eng-name {
  padding-top: 5px;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  color: gray;
}

.footer {
  width: 100%;
  background-color: #383838;
  color: #eee;
  overflow: hidden;
}

.footer a {
  color: #666;
}

.footer a:hover {
  color: #006498;
}

.footer .foot {
  width: 1200px;
  margin: 20px auto;
  overflow: hidden;
}

.footer .foot11 {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.footer .links {
  overflow: hidden;
}

.footer .links ul li {
  float: left;
  width: auto;
  margin-left: 100px;
}

.footer .links ul li h3 {
  font-size: 14px;
  color: #fff;
  padding-bottom: 15px;
  font-weight: normal;
}

.footer .links ul li a {
  font-size: 12px;
  color: #979797;
  display: block;
  line-height: 25px;
}

.footer .links ul li a:hover {
  color: #fff;
}

.footer .lxfs {
  margin-left: 10px;
  line-height: 24px;
  padding-top: 10px;
}

.footer .weixin {
  color: #fff;
  width: 115px;
  text-align: center;
  color: #a7a7a7;
  margin-top: 20px;
}

.footer .foot2 {
  border-top: #323232 1px solid;
}

.footer .foot2 p {
  display: block;
  width: 1170px;
  margin: 0 auto;
  line-height: 23px;
  padding: 10px 0;
  text-align: center;
}

.footer .foot2 p a {
  color: #a7a7a7;
}

.footer .hydl {
  width: 300px;
}

.footer .hydl h1 {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 15px;
}

.footer .bq {
  width: 100%;
  background-color: #2a2a2a;
  overflow: hidden;
}

.footer .bq .nr {
  width: 1200px;
  margin: 10px auto;
  text-align: center;
  line-height: 30px;
  overflow: hidden;
  color: #999;
}

.footer .bq .nr span {
  padding: 0 15px;
}

.footer .bq .nr span a {
  color: #999;
}
</style>
